































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, confirmed } from 'vee-validate/dist/rules'
import snakeCaseKeys from 'snakecase-keys'

import NotificationMixin from '@/mixins/notificationMixin'
import UseTerms from '@/partials/UseTerms.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

@Component({
  directives: { mask },
  components: {
    DateInput,
    ValidationProvider,
    ValidationObserver,
    UseTerms,
    PasswordConfirmField
  }
})
export default class RegisterServiceUserForm extends Mixins(NotificationMixin) {
  @Prop() person!: ServicePerson | EventPerson
  @Prop({ type: Boolean }) selfRegister!: boolean
  @Prop({ type: Boolean }) isPublicBuyer!: boolean
  @Prop({ type: String }) eventId!: 'string'
  @Prop({ type: String }) voucherId!: 'string'

  ufs = []
  treatments = ['Sr.', 'Sra.', 'Dr.', 'Dra.']
  done = true
  form: Record<string, any> = {
    name: '',
    email: '',
    sex: 'm',
    birthday: '2000-01-01 12:00:00',
    roles: [],
    sendWelcomeMail: true,
    confirm: '',
    profile: {
      treatment: 'Sr.',
      cpf: '',
      mobile: '',
      uf: 'DF'
    },
    errors: {
      name: '',
      email: '',
      sex: '',
      birthday: '',
      'profile.cpf': '',
      'profile.mobile': '',
      confirm: ''
    }
  }

  get title () {
    const title = {
      chief: this.selfRegister ? 'Bem-vindo, Chefe de Serviço!' : 'Cadastrar Chefe de Serviço',
      specialist: 'Cadastrar Especializando',
      visitor: 'Cadastrar Visitante',
      secretary: 'Cadastrar Secretária',
      speaker: 'Cadastrar Palestrante',
      subscriber: this.selfRegister ? 'Complete seu cadastro para o Evento' : 'Cadastrar Inscrito',
      sponsor: 'Cadastrar Patrocinador',
      studyReviewer: 'Cadastrar Avaliador',
      organizer: 'Cadastrar Organizador',
      staff: 'Cadastrar Membro da Equipe'
    }

    return title[this.person]
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })

    extend('email', {
      ...email,
      message: 'The email must be a valid email address.'
    })

    extend('confirmed', {
      ...confirmed,
      message: 'The {_field_} field does not match.'
    })
  }

  created () {
    this.validations()

    axios.get('geo/br/states')
      .then((response) => {
        this.ufs = response.data.data
      })

    axios.get('roles/user')
      .then((response) => {
        this.form.roles = [response.data.data.find((role: Role) => role.name === 'Usuário').id]
      })

    if (process.env.NODE_ENV === 'development') {
      this.fillFormWithDefaults()
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      email: '',
      sex: '',
      birthday: '',
      'profile.cpf': '',
      'profile.mobile': ''
    }
  }

  submit () {
    this.done = false
    const submitForm = {
      ...snakeCaseKeys(this.form),
      associate: false,
      profile: [] as Array<any>
    }
    const route = this.selfRegister ? '/public/users' : '/users'

    Object.entries(this.form.profile).forEach(([key, value]) => {
      submitForm.profile.push({ key: key, value: value })
    })
    if (this.person === 'chief') {
      submitForm.profile.push({ key: 'isChiefWithoutService', value: true })
    } else if (this.person === 'subscriber' && this.selfRegister) {
      if (this.isPublicBuyer) {
        submitForm.profile.push({ key: 'is_public_buyer', value: true })
        submitForm.profile.push({ key: 'event_id', value: this.eventId })
      } else {
        submitForm.profile.push({ key: 'event_id', value: this.eventId })
        submitForm.profile.push({ key: 'voucher_id', value: this.voucherId })
      }
    }

    axios.post(route, submitForm)
      .then((response) => {
        const responseData = { user: response.data.data, confirm: '' }

        if (!this.selfRegister) {
          responseData.confirm = this.form.confirm
        }
        this.$emit('submitted', responseData)
      })
      .catch(err => {
        const response = err.response
        const errors = response.data.errors
        if (errors) {
          this.setNotification('error', 'Os dados fornecidos não são válidos, verifique o formulário.')
          if (response.data.errors) {
            const errors = response.data.errors

            Object.entries(errors).map(([key, value]: [string, any]) => {
              this.form.errors[key] = value[0]
            })
          }
        }
      })
      .finally(() => { this.done = true })
  }

  fillFormWithDefaults () {
    Object.assign(this.form, {
      name: 'Nome do Usuário',
      email: 'email@teste.com',
      sex: 'm',
      birthday: '2000-01-01 12:00:00',
      password: '',
      profile: {
        treatment: 'Dr.',
        cpf: '000.000.000-00',
        mobile: '(00) 00000-0000',
        uf: 'DF',
        teotNumber: '0000',
        rg: '0000000',
        crm: '000000'
      }
    })
  }
}
