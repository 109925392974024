var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.selfRegister)?_c('cv-button',{staticClass:"mt-4 md:mt-0 md:ml-4",attrs:{"kind":"secondary"},on:{"click":_vm.fillFormWithDefaults}},[_vm._v(" Dados Padrão ")]):_vm._e()],1),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4"},[_c('cv-dropdown',{staticClass:"lg:col-span-2",attrs:{"label":"Tratamento"},model:{value:(_vm.form.profile.treatment),callback:function ($$v) {_vm.$set(_vm.form.profile, "treatment", $$v)},expression:"form.profile.treatment"}},_vm._l((_vm.treatments),function(treatment){return _c('cv-dropdown-item',{key:treatment,attrs:{"value":treatment}},[_vm._v(" "+_vm._s(treatment)+" ")])}),1),_c('ValidationProvider',{staticClass:"lg:col-span-10",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.name || errors[0],"label":"Nome"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('cv-dropdown',{staticClass:"lg:col-span-2",attrs:{"label":"Sexo"},model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}},[_c('cv-dropdown-item',{attrs:{"value":"m"}},[_vm._v(" Masculino ")]),_c('cv-dropdown-item',{attrs:{"value":"f"}},[_vm._v(" Feminino ")])],1),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"CPF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"invalid-message":_vm.form.errors['profile.cpf'] || errors[0],"label":"CPF"},model:{value:(_vm.form.profile.cpf),callback:function ($$v) {_vm.$set(_vm.form.profile, "cpf", $$v)},expression:"form.profile.cpf"}})]}}],null,true)}),_c('DateInput',{staticClass:"lg:col-span-2",attrs:{"label":"Nascimento","max-date":_vm.moment().format('YYYY-MM-DD')},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),_c('ValidationProvider',{staticClass:"lg:col-span-5",attrs:{"tag":"div","name":"mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"data-cy":"mobile","invalid-message":_vm.form.errors['profile.mobile'] || errors[0],"label":"Celular","type":"tel"},model:{value:(_vm.form.profile.mobile),callback:function ($$v) {_vm.$set(_vm.form.profile, "mobile", $$v)},expression:"form.profile.mobile"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-7",attrs:{"tag":"div","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"E-mail","invalid-message":_vm.form.errors.email !== 'RestorableUser' ? _vm.form.errors.email : '' || errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.form.errors.email === 'RestorableUser')?_c('cv-link',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.restoreUser}},[_vm._v(" Restaurar Usuário Removido ")]):_vm._e(),_c('cv-checkbox',{attrs:{"label":"Enviar email boas-vindas","value":"true"},model:{value:(_vm.form.sendWelcomeMail),callback:function ($$v) {_vm.$set(_vm.form, "sendWelcomeMail", $$v)},expression:"form.sendWelcomeMail"}})]}}],null,true)}),_c('cv-dropdown',{staticClass:"lg:col-span-5",attrs:{"label":"Estado"},model:{value:(_vm.form.profile.uf),callback:function ($$v) {_vm.$set(_vm.form.profile, "uf", $$v)},expression:"form.profile.uf"}},_vm._l((_vm.ufs),function(uf){return _c('cv-dropdown-item',{key:uf.id,attrs:{"value":uf.acronym}},[_vm._v(" "+_vm._s(uf.acronym)+" ")])}),1),(!_vm.selfRegister)?_c('PasswordConfirmField',{staticClass:"lg:col-span-4",attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}):_vm._e()],1)]),(_vm.selfRegister)?_c('div',{staticClass:"mt-8"},[_c('UseTerms')],1):_vm._e(),_c('div',{staticClass:"mt-4"},[(_vm.notification.open)?_c('cv-inline-notification',{attrs:{"kind":_vm.notification.kind,"title":_vm.notification.title,"sub-title":_vm.notification.message,"close-aria-label":"Fechar","low-contrast":false},on:{"close":function($event){_vm.notification.open = false}}}):_vm._e(),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"data-cy":"submit"},on:{"click":function($event){_vm.resetErrors(); handleSubmit(_vm.submit)}}},[_vm._v(" Registrar ")]):_c('cv-button-skeleton')],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }